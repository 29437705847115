import { ApiGetOssAuth, ApiObjectRecordsSave, ApiOssViewFileAuth } from "@/apis";
import OSS from "ali-oss";
import { DirectoryNames, DirectoryEnum } from "@/constants";
import * as utils from "@/utils";

// 从后端获取STS Token
async function getStsToken(id: number, time: number) {
  const response = await ApiGetOssAuth(id, time);
  return response;
}

// 上传
export async function uploadFile(
  directory: DirectoryEnum,
  file: File,
  fileName: string,
  extPath?: string
) {
  const directoryPath = DirectoryNames[directory];
  if (!directoryPath) {
    throw new Error("Invalid directory name");
  }
  const stsToken = await getStsToken(directory, 3600);
  const stsInfo = stsToken.data;

  const client = new OSS({
    region: "oss-" + stsInfo.region_id,
    accessKeyId: stsInfo.access_key_id,
    accessKeySecret: stsInfo.access_key_secret,
    stsToken: stsInfo.security_token,
    bucket: stsInfo.bucket_name,
  });
  const filePath = `${directoryPath}${extPath ? extPath : ""}/${fileName}`;
  console.log("filePath:", filePath);
  try {
    const recordsSave = await ApiObjectRecordsSave({
      directory_id: directory,
      object_key_list: [filePath],
      user_id: utils.get("userId"),
    });
    if (recordsSave.data) {
      const result = await client.put(filePath, file);
      let handleHost = "";
      // 处理上传后的业务域名拼接问题；
      const urlMappings = {
        "http://pins-document.oss-cn-beijing.aliyuncs.com": stsInfo.pins_domain,
        "https://pins-document.oss-cn-beijing.aliyuncs.com":
          stsInfo.pins_domain,
        "http://pins-app-resources.oss-cn-qingdao.aliyuncs.com":
          stsInfo.pins_domain,
        "https://pins-app-resources.oss-cn-qingdao.aliyuncs.com":
          stsInfo.pins_domain,
      };

      for (const [ossUrl, newDomain] of Object.entries(urlMappings)) {
        if (result.url.indexOf(ossUrl) > -1) {
          handleHost = result.url.replace(ossUrl, newDomain);
          break;
        }
      }

      const handleHostResult = handleHost;
      console.log("上传成功:", handleHostResult, recordsSave);
      return handleHostResult;
    } else {
      return recordsSave;
    }
  } catch (err) {
    console.error("上传失败:", err);
    throw err;
  }
}
// 批量上传;
export async function uploadMultiFiles(
  directory: DirectoryEnum,
  files: FileList | File[],
  extPath?: string
): Promise<any[]> {
  const results: any[] = [];
  for (const file of Array.from(files)) {
    try {
      const fileName = new Date().getTime().toString();
      const result = await uploadFile(directory, file, fileName, extPath);
      results.push(result);
    } catch (err) {
      console.error(`文件 ${file.name} 上传失败`, err);
    }
  }
  return results;
}

export async function getSignUrlBatch(fileUrls: any, duration: any) {
  const res = await ApiOssViewFileAuth({
    url_list: fileUrls,
    duration_seconds: duration,
    user_id: utils.get("userId"),
  });
  return res;
}