// 通过软件部运维平台查询目录ID进行枚举定义：
export enum DirectoryEnum {
  IMAGES = 1,
  VIDEOS = 2,
  DOCUMENTS = 3,
  HOSPITAL_MANAGE_2020 = 4,
  DOCTOR_CONTENT = 6,
  BANNER = 8,
  SONG_TEST = 11,
  PUBLISH = 19,
  REGULATION = 24,
  ARTICLE = 28,
  PICTRUE = 17,
  APPLICATION_MANAGEMENT = 33,
  NEWYEAR_PLAN = 43,
  GOODS = 44,
  NXSUPERVISE = 45,
  PARSONKIN_ACTIVITY = 46,
  //web_patient_information
  WEB_PATIENT_INFORMATION = 48,
  // ... 添加其他目录
}

export const DirectoryNames: { [key in DirectoryEnum]: string } = {
  [DirectoryEnum.IMAGES]: "images",
  [DirectoryEnum.VIDEOS]: "videos",
  [DirectoryEnum.DOCUMENTS]: "documents",
  [DirectoryEnum.SONG_TEST]: "song_test/123123",
  [DirectoryEnum.DOCTOR_CONTENT]: "doctorContent",
  [DirectoryEnum.REGULATION]: "regulation",
  [DirectoryEnum.NEWYEAR_PLAN]: "newYears_plan",
  [DirectoryEnum.ARTICLE]: "article",
  [DirectoryEnum.BANNER]: "banner",
  [DirectoryEnum.GOODS]: "goods",
  [DirectoryEnum.PICTRUE]: "picture",
  [DirectoryEnum.NXSUPERVISE]: "nxsupervise",
  [DirectoryEnum.PARSONKIN_ACTIVITY]: "parsonkinActivity",
  [DirectoryEnum.APPLICATION_MANAGEMENT]: "applicationManagement",
  [DirectoryEnum.PUBLISH]: "publish",
  [DirectoryEnum.HOSPITAL_MANAGE_2020]: "hospitalManage2020",
  [DirectoryEnum.WEB_PATIENT_INFORMATION]: "web_patient_information",
};

export const NameToDirectoryEnum: { [key: string]: DirectoryEnum } = {
  images: DirectoryEnum.IMAGES,
  videos: DirectoryEnum.VIDEOS,
  documents: DirectoryEnum.DOCUMENTS,
  song_test: DirectoryEnum.SONG_TEST,
  doctorContent: DirectoryEnum.DOCTOR_CONTENT,
  regulation: DirectoryEnum.REGULATION,
  newYears_plan: DirectoryEnum.NEWYEAR_PLAN,
  article: DirectoryEnum.ARTICLE,
  banner: DirectoryEnum.BANNER,
  goods: DirectoryEnum.GOODS,
  nxsupervise: DirectoryEnum.NXSUPERVISE,
  parsonkinActivity: DirectoryEnum.PARSONKIN_ACTIVITY,
  applicationManagement: DirectoryEnum.APPLICATION_MANAGEMENT,
  publish: DirectoryEnum.PUBLISH,
  hospitalManage2020: DirectoryEnum.HOSPITAL_MANAGE_2020,
};
